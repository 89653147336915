import { API } from "@project/shared"

const BASE_URL = "/parent_self_assessments"

export const getAllQuestionaires = ({
  facilityID,
  year,
  service_type,
}: {
  facilityID: string | number
  year: string | number
  service_type: string | number
}) => {
  return API.get(
    `${BASE_URL}/${facilityID}/${year}?service_type=${service_type}`
  )
}

export const createQuestionareAnswers = (
  facilityID,
  parentID,
  year,
  service_type,
  answers: {
    questionare_id: number
    answer: string
    opinion: string
  }[]
) => {
  return API.post(`${BASE_URL}/${facilityID}/answers/${parentID}/${year}`, {
    is_confirmed: true,
    parent_answers: answers,
    service_type,
  })
}

export const getNewEvaulationNotices = (parentID: number | string) =>
  API.get(`/parent-self-assessments/published/${parentID}`)
