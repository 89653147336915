import React, { useEffect, useState } from "react"
import { FormTitle, Divisor } from "../../../utils/shared-style"
import {
  CompletedContainer,
  Container,
  FormBody,
  LoaderContainer,
} from "./index.styles"
import Image from "next/image"
import { t } from "i18next"
import { notification, Spin, Typography } from "antd"
import { EvaluationQuestion } from "./EvaluationQuestion"
import { EvaluationAnswer } from "./EvaluationAnswer"
import { ChildButton } from "../../atoms/ChildButton"
import { useMutation, useQuery } from "react-query"
import {
  createQuestionareAnswers,
  getAllQuestionaires,
} from "../../../services/selfEvaluation"
import { getSelfChildDetail } from "../../../services"
import { useRouter } from "next/router"
import { serviceType } from "@project/shared"
import { scrollToFirstErrorFieldById } from "../../../utils"
import moment from "moment"

const Icon = (
  <Image
    src={"/assets/icons/service-delivery-sheet.svg"}
    height={22}
    width={22}
  />
)

const EvaluationForm: React.FC = () => {
  const router = useRouter()
  const [isCompleted, setIsCompleted] = useState(
    router?.query?.completed?.toString() ? true : false
  )
  const [isConfirmPage, setIsConfirmPage] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [handleSubmitClicked, setHandleSubmitClicked] = useState(false)
  const facilityID = router.query.id as string
  const target_year = router.query.target_year as string
  const service_type = router.query.service_type as string
  const year = target_year || new Date().getFullYear()

  useEffect(() => {
    setShowLoader(true)
    setTimeout(() => {
      setShowLoader(false)
      window.scrollTo({
        top: 0,
      })
    }, 250)
  }, [isConfirmPage])

  // Fetch parent ID to use while submitting evaluation form
  const { data: parentID } = useQuery<any, Error>(
    ["fetch-self-child"],
    getSelfChildDetail,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) => data?.Parent?.id,
    }
  )

  // Fetch self evaluation form template
  const { data: evalData, isLoading } = useQuery<any, Error>(
    ["fetch-self-evaluation"],
    () => getAllQuestionaires({ facilityID, year, service_type }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 3,
      select: ({ data }) => data,
      onError: (err: any) => err.status === 404 && router.push("/"),
    }
  )
  const questions = Object.values(evalData?.questionares || {}).flat()

  const [answers, setAnswers] = useState({})

  const { isLoading: isSubmitLoading, mutate } = useMutation(
    () =>
      createQuestionareAnswers(
        facilityID,
        parentID,
        year,
        +service_type?.toString(),
        Object.keys(answers).map((questionId) => ({
          questionare_id: +questionId,
          answer: answers[questionId]?.answer,
          opinion: answers[questionId]?.opinion,
        }))
      ),
    {
      onSuccess: () => {
        notification.success({
          message: `${t(
            "Evaluation information for parents is submitted successfully"
          )}`,
        })
        router.push(router.asPath + "&completed=1")
        setIsCompleted(true)
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const handleFormSubmit = () => {
    setHandleSubmitClicked(true)
    if (isConfirmPage)
      if (!Object.values(answers).some((answer: any) => !answer.answer)) {
        mutate()
      }
    const findEmptyFieldId = Object.keys(answers).find(
      (answer: any) => !answers[answer].answer
    )

    scrollToFirstErrorFieldById(String(findEmptyFieldId))

    if (!isConfirmPage && !findEmptyFieldId) setIsConfirmPage(true)
  }

  if (isCompleted) {
    return (
      <CompletedContainer>
        <div className={"complete-title"}>
          {t("Completion of evaluation form for parents")}
        </div>
        <div className={"complete-body"}>
          <div className={"cooperaion-block mb-25"}>
            {t("Thank you for your cooperation.")}
          </div>
          <div className={"button-container"}>
            <ChildButton
              typeOf={"primary"}
              text={t("Go to Top Page")}
              onClick={() => {
                router.push("/service-provision-record")
              }}
            />
          </div>
        </div>
      </CompletedContainer>
    )
  }

  return (
    <Container>
      <FormTitle>
        {Icon}
        {isConfirmPage
          ? t("Preview")
          : t("Request to fill in evaluation for guardians")}
      </FormTitle>
      <Divisor />
      {showLoader || isLoading ? (
        <LoaderContainer>
          <Spin size={"large"} />
        </LoaderContainer>
      ) : isConfirmPage ? (
        <FormBody>
          <>
            <Typography.Text>
              {`
            [${
              evalData?.assessment_settings?.guardian_aggregation_period_from !=
              "0001-01-01T00:00:00Z"
                ? moment(
                    evalData?.assessment_settings
                      ?.guardian_aggregation_period_from
                  ).format("YYYY年MM月DD日")
                : "-"
            }年度]施設名:${
                evalData?.assessment_settings?.facility?.facility_name_short ||
                ""
              }
             ${serviceType[evalData?.assessment_settings?.service_type] || ""}
          `}
            </Typography.Text>
            <br />
            <small>
              <br />
              <Typography.Text>
                {evalData?.assessment_settings?.message_entry ||
                  t(
                    "This evaluation form must be submitted as specified by the government. Registration will take approximately 5 minutes. We ask for your cooperation as much as possible."
                  )}
              </Typography.Text>
            </small>
            <div className={"answer-table"}>
              {questions &&
                questions
                  .sort((a: any, b: any) => {
                    return a.order - b.order
                  })
                  .map((question: any, i) => (
                    <EvaluationAnswer
                      key={i}
                      index={i + 1}
                      questionId={question?.id}
                      question={question?.question}
                      answer={answers?.[question?.id]?.answer}
                      opinion={answers?.[question?.id]?.opinion}
                    />
                  ))}
            </div>
            <div className={"confirm-page-btn-wrapper"}>
              <div>
                <ChildButton
                  typeOf={"primary"}
                  text={t("Submit")}
                  onClick={() => handleFormSubmit()}
                  isLoading={isSubmitLoading}
                />
              </div>
              <div>
                <ChildButton
                  typeOf={"secondary"}
                  text={t("Correct")}
                  onClick={() => setIsConfirmPage(false)}
                />
              </div>
            </div>
          </>
        </FormBody>
      ) : (
        <FormBody>
          <>
            <Typography.Text>
              {`
              [${
                evalData?.assessment_settings
                  ?.guardian_aggregation_period_from != "0001-01-01T00:00:00Z"
                  ? moment(
                      evalData?.assessment_settings
                        ?.guardian_aggregation_period_from
                    ).format("YYYY年MM月DD日")
                  : "-"
              }年度]施設名:${
                evalData?.assessment_settings?.facility?.facility_name_short ||
                ""
              }
               ${serviceType[evalData?.assessment_settings?.service_type] || ""}
            `}
            </Typography.Text>
            <br />
            <small>
              <br />
              <Typography.Text>
                {evalData?.assessment_settings?.message_entry ||
                  t(
                    "This evaluation form must be submitted as specified by the government. Registration will take approximately 5 minutes. We ask for your cooperation as much as possible."
                  )}
              </Typography.Text>
            </small>
            <div className={"answer-table"}>
              {questions &&
                questions
                  .sort((a: any, b: any) => {
                    return a.order - b.order
                  })
                  .map((question: any, i) => (
                    <EvaluationQuestion
                      key={i}
                      index={i + 1}
                      questionId={question?.id}
                      question={question?.question}
                      defaultAnswer={answers?.[question?.id]?.answer}
                      defaultOpinion={answers?.[question?.id]?.opinion}
                      setAnswer={setAnswers}
                      required={true}
                      options={evalData?.possible_answers || []}
                      handleSubmitClicked={handleSubmitClicked}
                    />
                  ))}
            </div>
            <div className={"confirm-page-btn-wrapper"}>
              <div>
                <ChildButton
                  typeOf={"primary"}
                  text={t("Confirm")}
                  onClick={() => handleFormSubmit()}
                  isLoading={isSubmitLoading}
                />
              </div>
              <div>
                <ChildButton
                  typeOf={"secondary"}
                  text={t("Cancel")}
                  onClick={() => {
                    router.push("/service-provision-record")
                  }}
                />
              </div>
            </div>
          </>
        </FormBody>
      )}
    </Container>
  )
}

export { EvaluationForm }
