import { Skeleton } from "antd"
import { t } from "i18next"
import moment from "moment"
import React, { useState } from "react"
import { useQuery } from "react-query"
import { getLatestServiceProvision } from "../../../services"
import { Divisor, FormTitle } from "../../../utils/shared-style"
import { DataCard } from "../../molecules"
import {
  Content,
  Footer,
  GridStyle,
  SituationContainer,
  Text,
  Title,
  TitleWrapper,
} from "./ServiceProvisionRecordCompoment.style"
import { useRouter } from "next/router"
import { OperationOptionForm } from "./OperationOptionForm/OperationOptionForm"
const ServiceProvisionRecordComponent: React.FC = () => {
  const router = useRouter()
  const [queryParams, setQueryParams] = useState({
    start: null,
    end: null,
    readStatus: "",
  })
  const {
    data: latestServiceProvision,
    isLoading,
    isFetching,
  } = useQuery<any, Error>(
    ["latest-service-provision", queryParams],
    getLatestServiceProvision,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: (response) => {
        const commentsWithServiceId =
          response?.data?.latestServiceComments?.map(
            (v) => v?.service_provision_results_id
          )
        const contentWithComments =
          response?.data?.latestServiceContent?.filter((val) =>
            commentsWithServiceId?.includes(val?.id)
          )
        return {
          data: {
            ...response?.data,
            latestServiceContent:
              queryParams?.start || queryParams?.end
                ? contentWithComments
                : contentWithComments?.splice(0, 3),
          },
        }
      },
    }
  )

  const serviceContent = latestServiceProvision?.data?.latestServiceContent
  const latestServiceComments =
    latestServiceProvision?.data?.latestServiceComments
  const serviceComments =
    latestServiceComments &&
    latestServiceComments.filter(
      (comment: any) => Number(comment?.service_provision_results_id) > 0
    )
  const latestServiceData = (data?: any) => [
    {
      title: "Use date",
      data: moment(data?.date).isValid()
        ? moment(data?.date).format("YYYY年MM月DD日")
        : "-",
    },
    {
      title: "Facility",
      data: data?.facility?.facility_name_short || "-",
    },
    {
      title: "Time",
      data:
        data?.start_time && data?.end_time
          ? `${data?.start_time}〜${data?.end_time}`
          : "-",
    },
    {
      title: "Body temperature",
      data: data?.temperature ? `${data?.temperature}度` : "-",
    },
  ]

  return (
    <>
      <OperationOptionForm
        setQueryParams={setQueryParams}
        queryParams={queryParams}
      />
      {isLoading || isFetching ? (
        <TitleWrapper>
          <FormTitle>
            <img src={"/assets/icons/title_icon.svg"} />
            <span style={{ marginLeft: "7px" }}>
              {t("Latest service provision record")}
            </span>
          </FormTitle>
          <Divisor />
          <Content>
            <Skeleton />
          </Content>
        </TitleWrapper>
      ) : (
        <>
          {serviceContent?.length > 0 ? (
            serviceContent?.map((content, index) => (
              <TitleWrapper key={index}>
                {index === 0 && (
                  <>
                    <FormTitle>
                      <img src={"/assets/icons/title_icon.svg"} />
                      <span style={{ marginLeft: "7px" }}>
                        {t("Latest service provision record")}
                      </span>
                    </FormTitle>
                    <Divisor />
                  </>
                )}

                <Content
                  style={{
                    paddingTop: index !== 0 ? "20px" : "",
                  }}
                >
                  <Title>{t("Use facility")}</Title>
                  <GridStyle>
                    {latestServiceData(content)?.map((data, index) => {
                      if (index === 0) {
                        let date = serviceComments?.find(
                          (v) => v?.service_provision_results_id === content?.id
                        )?.date
                        date = moment(date).isValid()
                          ? moment(date).format("YYYY年MM月DD日")
                          : "-"

                        return (
                          <DataCard
                            key={index}
                            title={t(data?.title)}
                            data={t(date)}
                            className={"data-card"}
                          />
                        )
                      }
                      return (
                        <DataCard
                          key={index}
                          title={t(data?.title)}
                          data={t(data?.data)}
                          className={"data-card"}
                        />
                      )
                    })}
                  </GridStyle>
                  <SituationContainer>
                    <Title>{t("Today's situation")}</Title>
                    {serviceComments
                      ?.filter((val) =>
                        moment(val?.date?.split("T")?.[0]).isSame(
                          content?.date?.split("T")?.[0]
                        )
                      )
                      .slice(0, 1)
                      ?.map((serviceComment, index) => (
                        <Text key={index}>
                          {serviceComment?.comment?.length > 300
                            ? t(serviceComment?.comment.slice(0, 250)) + "..."
                            : t(serviceComment?.comment)}
                        </Text>
                      ))}
                    <Footer
                      onClick={() => {
                        const commentId =
                          serviceComments?.find(
                            (v) =>
                              v?.service_provision_results_id === content?.id
                          )?.id || 0
                        router.push(
                          `/service-provision-record/details/${commentId}`
                        )
                      }}
                    >
                      {t("See more")}
                      {` >>`}
                    </Footer>
                  </SituationContainer>
                </Content>
              </TitleWrapper>
            ))
          ) : (
            <TitleWrapper>
              <FormTitle>
                <img src={"/assets/icons/title_icon.svg"} />
                <span style={{ marginLeft: "7px" }}>
                  {t("Latest service provision record")}
                </span>
              </FormTitle>
              <Divisor />
              <Content>
                <h1> {t("No Data Found")}</h1>
              </Content>
            </TitleWrapper>
          )}
        </>
      )}
    </>
  )
}

export { ServiceProvisionRecordComponent }
