import React, { useEffect } from "react"
import { useQuery } from "react-query"
import { t } from "i18next"
import { getNewEvaulationNotices } from "../../../services/selfEvaluation"
import { SERVICE_OPTION } from "@project/shared"
import { NoticeComponent } from "./Notice"
import { useRouter } from "next/router"
import NotificationFacility from "../NotificationFacility"
import moment from "moment"
import { getSelfChildDetail } from "../../../services/childParent"

export const Notice = () => {
  // Fetch parent ID to use while submitting evaluation form
  const { data: childData, isLoading } = useQuery<any, Error>(
    ["fetch-self-child"],
    getSelfChildDetail,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 100,
      retry: 1,
      select: ({ data }) => ({
        parentID: data?.Parent?.id,
        isApplied: data?.is_applied || false,
        canFacilityApply: data?.can_facility_application_apply || false,
        serviceType:
          data?.child_receiving_certificates?.[
            data?.child_receiving_certificates?.length - 1
          ]?.service_type,
      }),
    }
  )

  const router = useRouter()

  // this changes after self assessment is answered
  const { completed } = router?.query

  const { data: noticeData, refetch } = useQuery<any, Error>(
    ["fetch-self-child", childData?.parentID],
    () => getNewEvaulationNotices(childData.parentID),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!childData?.parentID && !isLoading,
      retry: 1,
      select: ({ msg }) => msg,
    }
  )

  useEffect(() => {
    if (completed) {
      refetch()
    }
  }, [completed])

  return (
    <>
      {noticeData?.id && !noticeData?.answered_parent_report_id ? (
        <NoticeComponent
          tag={t("Important")}
          heading={t(
            "Please help us fill out the evaluation sheet for parents."
          )}
          text={`[${
            noticeData?.publication_date != "0001-01-01T00:00:00Z"
              ? moment(noticeData?.publication_date)?.isValid()
                ? moment(noticeData?.publication_date).format("YYYY年")
                : ""
              : "-"
          }度]施設名:${noticeData?.facility?.facility_name_short || ""} ${
            noticeData
              ? SERVICE_OPTION.map((option) => option.label)[
                  +noticeData?.service_type - 1
                ]
              : ""
          }`}
          onClick={() =>
            router.push(
              noticeData
                ? `/service-provision-record/self-evaluation-variation/${noticeData?.facility_id}?target_year=${noticeData?.target_year}&service_type=${noticeData?.service_type}`
                : "#"
            )
          }
        />
      ) : (
        ""
      )}
      {!isLoading && !childData?.isApplied && childData?.canFacilityApply && (
        <NotificationFacility />
      )}
    </>
  )
}
